<template>
  <div v-if="!Object.entries(newSecret).length">
    <h2>Hey! What's your secret?</h2>
    <textarea v-model="secret"></textarea>

    <div :class="{'deactived': !secret}">
      <p>Who do you want to send this to?</p>
      <ul class="flex flex-wrap justify-content-center">
        <li v-for="(r, index) in availableRecipients" :key="'recipient-'+ index" :class="{'active': isSelected(r)}">
          <a href="#" class="button" @click.prevent="toggle(r)">{{ r }}</a>
        </li>
      </ul>

      <p>How many times do you want this secret to be viewable?
      <input v-model="numberOfShares" type="number" min="3" max="10" /></p>

      <div class="button-wrapper">
        <button class="button" @click="store">Share</button>
      </div>
    </div>
  </div>

  <div v-else>
    <h2>Secret saved!</h2>
    <p>Here's the autogenerated link to view your saved secret:</p>
    <ul class="flex flex-wrap justify-content-center">
      <li><a class="button" :href="'/#/show/' + newSecret.ID" target="_blank">View</a></li>
      <li><a class="button" href="#" @click="copyLink">Copy to clipboard</a></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: { },
  data() {
    return {
      secret: "",
      numberOfShares: 5,
      availableRecipients: ['Nobody'],
      selectedRecipients: ['Nobody'],
      newSecret: {}, // after the secret is saved, this will be populated with the object
      newSecretAddress: "",
    }
  },
  methods: {
    toggle(r) {
      if (r === "Nobody") {
        this.selectedRecipients = ['Nobody'];
        return;
      }

      this.selectedRecipients = this.selectedRecipients.filter((name) => name !== "Nobody");
      this.selectedRecipients.push(r);
    },
    isSelected(r) {
      for (let index = 0; index < this.selectedRecipients.length; index++) {
        if (r === this.selectedRecipients[index]) {
          return true;
        }
      }
      return false;
    },
    store() {
      const postData = new URLSearchParams();
      postData.append("secret", this.secret);
      postData.append("numberOfShares", this.numberOfShares);
      postData.append("shareWith", this.selectedRecipients);

      this.$api.post("/store", postData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then((r) => {
        this.newSecret = r.data.secret;
        this.newSecretAddress = r.data.url;
      }).catch(() => {
        // TODO: handle error!
     });
    },
    copyLink() {
      navigator.clipboard.writeText(this.newSecretAddress);
    }
  },
  mounted() {
    this.$api.get("recipients").then((r) => {
      for (let index = 0; index < r.data.length; index++) {
        this.availableRecipients.unshift(r.data[index]);
      }
    })
  }
}
</script>

<style lang="css" scoped>
h2 {
  text-align: left;
  color: white;
}

p {
  color: white;
}

ul {
  margin: 0 0 30px;
  padding: 0;
  list-style-type: none;
}

li {
  margin: 0 15px;
  display: block;
  border-radius: 3px;
  padding: 5px 15px;
}

.button {
  color: white;
  text-decoration: none;
  background-color: #6f4bf3;
  padding: 5px 15px;
  display: block;
  transition: all 0.3s ease, filter 0s linear;
  border: 0;
  font-size: 1.25rem;
  border-radius: 3px;
  cursor: pointer;
}

li.active .button {
  background-color: white;
  color: #6f4bf3;
}

.deactived {
  opacity: 0.25;
}

.button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 60px;
}

button.button {
  text-transform: uppercase;
  font-size: 1.5rem;
  padding: 10px 30px;
}

.button:hover {
  filter: brightness(1.1);
}
</style>
